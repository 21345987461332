import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { red, green, blue } from "@mui/material/colors";

import * as React from "react";
import { StoneControl } from "../../controls/StoneControl";
import { Game } from "../../models";
import { BoardField, Item } from "../GameControl";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Button, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { getGameConfig } from "../../utils/gameConfig";

interface GameBoardControlProps {
  game: Game;
  setGame?: (game: Game) => void;
  myTurn?: boolean;
  // hoverKey: string | undefined;
  // setHoverKey: React.Dispatch<React.SetStateAction<string | undefined>>;
  placeStone?: (index: number) => Promise<void>;
  winRow?: number[] | undefined;
  sx?: any;
}

const GameBoard = styled("div")(({ theme }) => ({
  // padding: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    gap: 1,

    minWidth: "300px",
    minHeight: "300px",
  },
  [theme.breakpoints.up("sm")]: {
    gap: 2,
    minWidth: "500px",
    minHeight: "500px",
  },

  // gridTemplateColumns: "repeat(4, "25%")",
  gridTemplateColumns: "repeat(4, 25%)",
  gridTemplateRows: "repeat(4, 25%)",
  marginTop: theme.spacing(2),
  display: "grid",
}));

export const Tile = styled("div")(({ theme }) => ({
  // [theme.breakpoints.down("sm")]: {
  //   width: "50px",
  //   height: "50px",
  // },
  // [theme.breakpoints.up("sm")]: {
  //   width: "75px",
  //   height: "75px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   width: "100px",
  //   height: "100px",
  // },
  backgroundColor: "white",
  border: "1px solid",
  p: theme.spacing(1),
  borderRadius: 2,
  textAlign: "center",
  fontSize: "0.875rem",
  fontWeight: "700",
}));

export function GameBoardControl(props: GameBoardControlProps) {
  const { SHOW_CONFIRM_BUTTONS } = getGameConfig();
  let [hoverKey, setHoverKey] = React.useState<string>();
  let [errorKey, setErrorKey] = React.useState<string>();
  let [confirmKey, setConfirmKey] = React.useState<string>();
  const { game, myTurn, placeStone, winRow, sx, setGame } = props;

  const undoPlaceStone = (index: number) => {
    setConfirmKey("");
  };

  return (
    <GameBoard sx={sx}>
      {/* <Box gridColumn="span 8"> */}
      {game.board &&
        game.board.map((b: any, index: number) => {
          let currentField: BoardField = { ...b };
          // console.log("🚀 ~ currentField", currentField);
          if (myTurn && currentField.key === hoverKey) {
            currentField.hover = true;
          }
          if (myTurn && currentField.key === errorKey) {
            currentField.error = true;
          }
          if (myTurn && currentField.key === confirmKey) {
            currentField.showConfirm = true;
          }

          return (
            <>
              {currentField.error && (
                <Tile
                  onMouseLeave={(e) => {
                    setHoverKey("");
                    setErrorKey("");
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    opacity: "0.3",
                    bgcolor: "red",
                  }}
                >
                  <DoNotDisturbIcon />
                </Tile>
              )}
              {currentField.showConfirm && (
                <Tile
                  onMouseLeave={(e) => {
                    setHoverKey("");
                    setErrorKey("");
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    // opacity: "0.3",
                    // bgcolor: "red",
                  }}
                >
                  <IconButton onClick={() => placeStone!(index)}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setHoverKey("");
                      undoPlaceStone(index);
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tile>
              )}
              {!currentField.error && !currentField.showConfirm && (
                <Tile
                  key={`boardField-${index}`}
                  onMouseEnter={(e) => {
                    setHoverKey(b.key);
                  }}
                  onMouseLeave={(e) => {
                    setHoverKey("");
                    setErrorKey("");
                  }}
                  // onClick={(e) => {setConfirmKey(b.key)}}
                  onClick={(e) => {
                    if (myTurn && game.currentStone) {
                      if (SHOW_CONFIRM_BUTTONS) {
                        setConfirmKey(b.key);
                      } else {
                        if (!b.stone) {
                          console.log("place stone...");
                          placeStone!(index);
                          setHoverKey("");
                        } else {
                          if (b.stone) {
                            setErrorKey(b.key);
                          }
                          console.log("not my turn");
                        }
                      }
                    }
                  }}
                  sx={{
                    borderStyle: currentField.hover ? "dotted" : "solid",
                    // background: currentField.hover ? (b.stone ? "red" : "") : "",
                    borderColor: currentField.hover
                      ? b.stone
                        ? "red"
                        : "lightgrey"
                      : "lightgrey",
                    cursor: currentField.hover ? "pointer" : "",
                    backgroundColor:
                      winRow && winRow.indexOf(index) !== -1
                        ? "gold"
                        : currentField.error
                        ? "red"
                        : "",
                  }}
                >
                  {b.stone && (
                    <StoneControl stone={b.stone} iconSet={game.iconSet} />
                  )}
                  {myTurn &&
                    !b.stone &&
                    game?.currentStone &&
                    currentField.hover && (
                      <Box
                        sx={{
                          // backgroundColor: "yellow",
                          opacity: "20%",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <StoneControl
                          stone={game?.currentStone}
                          iconSet={game?.iconSet}
                          preview={true}
                        />
                      </Box>
                    )}
                </Tile>
              )}
            </>
          );
        })}
    </GameBoard>
  );
}
