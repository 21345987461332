import "./App.css";

import { GameList } from "./views/GameList";

import { Box } from "@mui/material";
import { Amplify } from "aws-amplify";
// import awsconfig from "./aws-exports";
// Renew api-key at
// https://eu-central-1.console.aws.amazon.com/appsync/home
// Settings

Amplify.configure({
  aws_project_region: "eu-central-1",
  aws_appsync_graphqlEndpoint:
    "https://yptga6eccrgepdj3qlczz2rdvq.appsync-api.eu-central-1.amazonaws.com/graphql",
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-4bizqth3n5fynh26ioeaaw3hq4",
});
// DataStore.configure({
//   syncExpressions: [
//     syncExpression(Game, () => {
//       // return (game) => game.createdAt("gt", "1");
//       return (g) => g.title("beginsWith", "Game");
//     }),
//   ],
// });

function App() {
  console.log("app render...");
  // const models = await DataStore.query(Game);
  // console.log(models);
  return (
    <Box className="App">
      {/* <header className="App-header"></header> */}
      <GameList />
    </Box>
  );
}

export default App;
