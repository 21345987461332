import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  keyframes,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { DataStore } from "@aws-amplify/datastore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import React from "react";
import { useNavigate } from "react-router-dom";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { Field, Game } from "../models";
import {
  addGameToLocalStorage,
  createGame,
  createNewBoard,
  dateSorter,
  getMyGamesFromLocalStorage,
  isMyTurn,
  myOpponent,
  whoAmI,
} from "../utils/common";
import { bgcolor } from "@mui/system";
import { GameSeries } from "../models";
import { ExpertInfoIcon } from "./Game/GameInfoHeaderControl";
import { WelcomeView } from "./WelcomeView";
import { GameInfoView } from "./GameInfoView";
import { Hub } from "aws-amplify";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

enum GameListKind {
  active = 0,
  ended = 1,
  pending = 2,
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function GameList() {
  const [myGames, setMyGames] = React.useState<string[]>();
  const [openGames, setOpenGames] = React.useState<Game[]>();
  const [endedGames, setEndedGames] = React.useState<Game[]>();
  let navigate = useNavigate();

  const [nsLoaded, setNsLoaded] = React.useState(false);

  React.useEffect(() => {
    i18next.loadNamespaces("gameList", (err, t) => {
      /* ... */
      if (!err) {
        setNsLoaded(true);
      } else {
        console.log("ERROR LOADING NS", err);
      }
    });
  }, []);
  const { t } = useTranslation();

  let [count, setCount] = React.useState<number>();
  let [list, setList] = React.useState<GameListKind>(GameListKind.active);

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: GameListKind
  ) => {
    setList(newValue);
  };

  const initGameList = async () => {
    console.log("initGameList");

    // let myGamesStr = localStorage.getItem("myGames");

    // if (myGamesStr) {
    // let myGames: string[] = JSON.parse(myGamesStr);
    let myGames = getMyGamesFromLocalStorage();
    console.log("mygames: ", myGames);

    if (myGames.length > 0) {
      console.log("init Game List");

      let gameList = await DataStore.query(Game, (c) =>
        c.or((c) => {
          const red = myGames.reduce((c, id) => c.id("eq", id), c);
          // console.log("🚀 ~ red", red);
          return red;
        })
      );
      // console.log("🚀 ~ gameList1", gameList);
      let filterSeries = gameList
        .filter((g) => g.seriesId)
        .map((item) => item.seriesId);
      // console.log("found games with series...", filterSeries);

      if (filterSeries && filterSeries.length > 0) {
        let series = await DataStore.query(GameSeries, (s) =>
          s.or((s) => {
            const red = filterSeries.reduce((s, id) => s.id("eq", id!), s);
            // console.log("🚀 ~ red found", red);
            return red;
          })
        );
        console.log("🚀 ~ found series", series);

        const seriesGameIds: (string | null | undefined)[] = series
          .map((s) => {
            return s.gameIds;
          })
          .flat()
          .filter((s) => {
            return s !== undefined && s !== null;
          });
        console.log("found games from series...", seriesGameIds);
        let moreGames = await DataStore.query(Game, (c) =>
          c.or((c) => {
            const red = seriesGameIds.reduce((c, id) => {
              return c.id("eq", id || "");
              // else return c;
            }, c);
            console.log("🚀 ~ red found", red);
            return red;
          })
        );
        console.log("🚀 ~ found moreGames", moreGames);
        gameList = [...gameList, ...(moreGames as Game[])];
        console.log("🚀 ~ extended gameList", gameList);

        const uniqueIds: string[] = [];
        gameList = gameList.filter((g) => {
          if (uniqueIds.indexOf(g.id) === -1) {
            uniqueIds.push(g.id);
            return true;
          } else {
            return false;
          }
        });
        // gameList = [...Array.from(new Set(gameList.map((item: any) => item)))];
      }

      const openGames = gameList
        .sort(dateSorter("updatedAt", "DESC"))
        .filter((g) => !g.winner);
      setOpenGames(openGames);
      const finishedGames = gameList
        .sort(dateSorter("updatedAt", "DESC"))
        .filter((g) => g.winner);
      setEndedGames(finishedGames);
      console.log("🚀 ~ gameList", gameList);
      // setGames([...openGames, ...finishedGames]);
      setCount((finishedGames?.length || 0) + (openGames.length || 0));
      setMyGames([...myGames]);
    } else {
      setCount(0);
    }
    // }
  };

  const deleteGame = async (game: Game) => {
    console.log("🚀 ~ DELETE game", game);
    const gameId = game.id;
    // const deleted = await DataStore.delete(game.id);
    const deleted = await DataStore.delete(Game, (game) =>
      game.id("eq", gameId)
    );
    console.log("🚀 ~ deleted", deleted);
    initGameList();
  };

  const createNewGame = () => createGame({ count: count }, navigate);

  let [lastUpdate, setLastUpdate] = React.useState<number>(
    new Date().getTime()
  );

  React.useEffect(() => {
    initGameList();
    const sub = DataStore.observeQuery(Game).subscribe(({ items }) => {
      console.log("got update...", items);
      setLastUpdate(new Date().getTime());
      initGameList();
    });

    window.addEventListener("focus", () => {
      console.log("viewed");
      initGameList();
    });

    // DataStore.clear();
    // DataStore.start();
    // // Create listener
    // const listener = Hub.listen("datastore", async (hubData) => {
    //   console.log("hubstatus: listen");
    //   const { event, data } = hubData.payload;
    //   if (event === "networkStatus") {
    //     console.log(`hubstatus: User has a network connection: ${data.active}`);
    //   }
    // });

    return () => {
      sub.unsubscribe();
      // listener();
    };
  }, []);

  console.log("Count: ", count);

  if (count === 0) {
    return (
      <Box m={3}>
        {/* {createGameButton()} */}
        <WelcomeView />
        {/* <GameInfoView title={t("gameList:gameInfoTitle")} /> */}
      </Box>
    );
  }
  const blink = keyframes`
  from { opacity: 0.7;  }
  to { opacity: 1; }
`;

  return (
    <Box
      alignItems={"center"}
      display="flex"
      flexDirection={"column"}
      // bgcolor="whitesmoke"
    >
      <Box m={3}>{createGameButton()}</Box>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "600px",
          mb: 4,
          // border: "1px solid lightgrey",
          // bgcolor: "whitesmoke",
        }}
      >
        <Tabs
          value={list}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          {openGames && (
            <Tab
              label={t("gameList:activeGamesTab", {
                count: openGames.length || 0,
              })}
              {...a11yProps(0)}
            />
          )}
          {endedGames && (
            <Tab
              label={t("gameList:endedGamesTab", {
                count: endedGames.length || 0,
              })}
              {...a11yProps(1)}
            />
          )}
        </Tabs>

        <TabPanel value={list} index={GameListKind.active}>
          {!count && (
            <Typography
              sx={{ animation: `${blink} 1s linear infinite alternate` }}
            >
              {t("gameList:loadingGames")}
            </Typography>
          )}
          {count !== undefined && (!openGames || openGames.length === 0) && (
            <Typography variant="overline">
              t('gameList:noGamesFound')
            </Typography>
          )}
          {openGames && list === GameListKind.active && (
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {openGames.map((g: Game, index) => {
                return (
                  <>
                    {getGameListItem(index, g)}
                    {index < openGames.length - 1 && (
                      <Divider
                        variant="middle"
                        sx={{ bgcolor: "whiteSmoke", mb: 0 }}
                      />
                    )}
                  </>
                );
              })}
            </List>
          )}
        </TabPanel>
        <TabPanel value={list} index={GameListKind.ended}>
          {(!endedGames || endedGames.length === 0) && (
            <Typography variant="overline">
              {t("gameList:noEndedGamesFound")}
            </Typography>
          )}
          {endedGames && list === GameListKind.ended && (
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {endedGames.map((g: Game, index) => {
                return (
                  <>
                    {getGameListItem(index, g)}
                    {index < endedGames.length - 1 && (
                      <Divider
                        variant="middle"
                        sx={{ bgcolor: "whiteSmoke", mb: 0 }}
                      />
                    )}
                  </>
                );
              })}
            </List>
          )}
        </TabPanel>
      </Paper>
    </Box>
  );

  function getGameListItem(index: number, g: Game) {
    const labelId = `checkbox-list-label-${index}`;

    const getPlayerName = (x: number, game: Game) => {
      if (x === 1) {
        return game.player1;
      } else if (x === 2) {
        return game.player2;
      } else {
        return `${x}`;
      }
    };
    const myTurn = isMyTurn(g);
    return (
      <ListItem
        sx={{ marginX: 0 }}
        // sx={{ backgroundColor: whoAmI(g) === g.winner ? "gold" : "" }}
        key={index}
        secondaryAction={
          <IconButton
            onClick={() => deleteGame(g)}
            edge="end"
            aria-label="comments"
          >
            <DeleteForeverIcon />
          </IconButton>
        }
        disablePadding
      >
        <ListItemButton
          role={undefined}
          href={`/game/${g.id}`}
          // onClick={handleToggle(value)}
          dense
        >
          <ListItemText
            id={labelId}
            primary={
              <>
                <Box
                  component={"span"}
                  display={"flex"}
                  flexDirection="row"
                  alignItems="flex-end"
                  justifyContent={"space-between"}
                >
                  <Box display={"flex"} alignItems="center">
                    {myTurn && (
                      <NotificationImportantIcon
                        sx={{ ml: -4, mr: 1, color: "orange" }}
                      />
                    )}

                    {whoAmI(g) === g.winner && (
                      <EmojiEventsIcon sx={{ ml: -4, mr: 1, color: "gold" }} />
                    )}
                    <Box display={"flex"}>
                      <Typography component={"label"}>{g.title}</Typography>
                      {g.expertMode && (
                        <ExpertInfoIcon sx={{ fontSize: "smaller" }} />
                      )}
                    </Box>
                  </Box>
                  {
                    <Typography component={"label"} variant="caption">
                      {t("gameList:player1VsPlayer2", {
                        p1: g.player1 || "...",
                        p2: g.player2 || "...",
                      })}
                      {/* {g.player1 || "..."} vs.{g.player2 || "..."} */}
                    </Typography>
                  }
                </Box>
                {g.winner && (
                  <Typography component={"label"} variant="overline">
                    {whoAmI(g) === g.winner
                      ? t("gameList:youWon")
                      : t("gameList:playerXWon", { player: g.winner })}
                  </Typography>
                )}
                {!g.winner && !myTurn && g.currentPlayer && (
                  <Typography component={"label"} variant="overline">
                    {!g.currentStone
                      ? t("gameList:chooseAStone")
                      : t("gameList:playerXTurn", {
                          player: getPlayerName(g.currentPlayer, g),
                        })}
                    {/* {g.currentStone && getPlayerName(g.currentPlayer, g)} ist am
                    Zug
                    {!g.currentStone && myOpponent(g)} ist am Zug */}
                  </Typography>
                )}
                {!g.winner && myTurn && (
                  <Box display={"flex"} alignItems="center">
                    <Typography
                      color="orange"
                      component={"label"}
                      variant="overline"
                    >
                      {/* {!g.currentStone
                        ? `${myOpponent(g)} wählt einen Stein...`
                        : "Du bist am Zug"} */}

                      {!g.currentStone
                        ? t("gameList:opponentChoosesStone", {
                            p: myOpponent(g),
                          })
                        : t("gameList:itsYourTurn")}
                    </Typography>
                  </Box>
                )}
              </>
            }
            secondary={<Box component={"label"}>{g.description}</Box>}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  function createGameButton() {
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={() => createNewGame()}
      >
        {t("gameList:newGameButton")}
      </Button>
    );
  }
}
