export interface GameConfig {
  SHOW_CONFIRM_BUTTONS: boolean;
}

export const getGameConfig = (): GameConfig => {
  const conf = JSON.parse(localStorage.getItem("gameConfig") || "{}");

  return {
    SHOW_CONFIRM_BUTTONS: conf.SHOW_CONFIRM_BUTTONS || false,
  };
};

export const saveGameConf = (c: GameConfig) => {
  localStorage.setItem("gameConfig", JSON.stringify(c));
};
