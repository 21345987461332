import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  PaperProps,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { StoneControl } from "../controls/StoneControl";
import { Game, StoneType } from "../models";
import { styled } from "@mui/material/styles";
import { GameBoardControl } from "./Game/GameBoardControl";
import Draggable from "react-draggable";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { getGameConfig } from "../utils/gameConfig";
import { useTranslation } from "react-i18next";

export interface SelectStoneDialogProps {
  open: boolean;
  // selectedValue?: Stone;
  onClose: (value: StoneType) => void;
  stones: StoneType[];
  game: Game;
}

export function PaperComponent(props: PaperProps) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("md"));

  let sx = {};
  if (xs) {
    sx = {
      minHeight: xs ? "100vh !important" : "100%",
      minWidth: xs ? "100vw !important" : "100%",
      maxHeight: xs ? "100vh !important" : "100%",
      maxWidth: xs ? "100vw !important" : "100%",
      margin: xs ? 0 : "",
    };
  }

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} sx={{ m: "0 !important", p: 0, ...sx }} />
    </Draggable>
  );
}

const StoneSelectList = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    justifyContent: "center",
    display: "flex",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(4, 1fr)",

    display: "grid",
    justifyContent: "space-around",
  },
  // gap: theme.spacing(2),

  // minWidth: "300px",
  // minHeight: "300px",
  // width: "100%",
  // height: "100%",
  // gridTemplateColumns: "repeat(4, "25%")",

  padding: theme.spacing(1),

  flexWrap: "wrap",

  alignContent: "space-around",
  // overflow: "auto",
}));

// m={4}
// mt={2}
// sx={{
//   display: "grid",
//   gap: 4,
//   gridTemplateColumns: "repeat(4, 1fr)",
// }}

export function SelectStoneDialog(props: SelectStoneDialogProps) {
  const { onClose, open, stones, game } = props;
  const { SHOW_CONFIRM_BUTTONS } = getGameConfig();
  const { t } = useTranslation();
  console.log("🚀 ~ stones", stones);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    // if (selectedValue) onClose(selectedValue);
  };

  const remainingStones = getRemainingStones(game, stones);

  const confirmStoneSelection = (stone: StoneType) => {
    onClose(stone);
  };

  let [toggleBoard, setToggleBoard] = React.useState<boolean>(false);

  let [showConfirm, setShowConfirm] = React.useState<number>(-1);

  if (game.winner !== null) {
    return <></>;
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }}>
          <Typography variant="body1">
            {t("gameControl:chooseStoneTitle")}
          </Typography>
          <Typography variant="caption">
            {t("gameControl:chooseNextStoneForPlayerX", {
              player: game.currentPlayer === 1 ? game.player1 : game.player2,
            })}
          </Typography>
        </DialogTitle>
        <Box mb={2} display={"flex"} justifyContent="center">
          <Button
            variant="outlined"
            onClick={() => setToggleBoard(!toggleBoard)}
          >
            {!toggleBoard && (
              <Typography>{t("gameControl:showBoardButton")}</Typography>
            )}
            {toggleBoard && (
              <Typography>{t("gameControl:chooseStoneButton")}</Typography>
            )}
          </Button>
        </Box>
        {toggleBoard && (
          <Box display={"flex"} alignItems="center" justifyContent={"center"}>
            <GameBoardControl
              sx={{
                mt: 0,
                m: 4,
                height: "350px",
                width: "350px",
                minHeight: "350px !important",
                minWidth: "350px !important",
              }}
              game={game}
            />
          </Box>
        )}
        {!toggleBoard && (
          <StoneSelectList
          // sx={{
          //   height: xs ? "100vh" : "100%",
          //   width: xs ? "100vw" : "100%",
          //   margin: xs ? 0 : "",
          // }}
          >
            {remainingStones.map((stone, index) => {
              return (
                <>
                  {showConfirm !== index && (
                    <IconButton
                      key={`stoneIcon-${index}`}
                      sx={{
                        ml: 1,
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "whitesmoke",
                          // border: "1px dashed black",
                        },
                      }}
                      // sx={{
                      //   height: "75px",
                      //   width: "75px",
                      // }}
                      onClick={() => {
                        if (SHOW_CONFIRM_BUTTONS) {
                          setShowConfirm(index);
                        } else {
                          confirmStoneSelection(stone);
                        }
                      }}
                    >
                      <Avatar
                        sx={{
                          fontWeight: "inherit",
                          height: "75px",
                          width: "75px",
                          bgcolor: "white",
                          color: "white",
                        }}
                      >
                        <StoneControl stone={stone} iconSet={game?.iconSet} />
                      </Avatar>
                    </IconButton>
                  )}
                  {showConfirm === index && (
                    <IconButton
                      key={`stoneIcon-${index}`}
                      sx={{
                        ml: 1,
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "whitesmoke",
                          // border: "1px dashed black",
                        },
                      }}
                      // sx={{
                      //   fontWeight: "inherit",
                      //   height: "75px",
                      //   width: "75px",
                      //   bgcolor: "white",
                      //   color: "white",
                      // }}
                    >
                      {/* <IconButton onClick={() => confirmStoneSelection(stone)}> */}
                      <CheckIcon onClick={() => confirmStoneSelection(stone)} />
                      {/* </IconButton>
                      <IconButton
                        onClick={() => {
                          setShowConfirm(-1);
                        }}
                      > */}
                      <CancelIcon
                        onClick={() => {
                          setShowConfirm(-1);
                        }}
                      />
                      {/* </IconButton> */}
                    </IconButton>
                  )}
                </>
              );
            })}
          </StoneSelectList>
        )}
      </Dialog>
      {!open && <RemainingStonesControl game={game} stones={stones} />}
    </>
  );
}
function getRemainingStones(game: Game, stones: StoneType[]) {
  const placedStones = game.board
    ?.filter((field) => {
      return field?.stone;
    })
    .map((f) => f?.stone?.key);

  const remainingStones = stones
    .filter((s) => {
      // console.log("filter remaining stones (stone): ", s);
      // console.log("filter remaining stones (board): ", game.board);
      return (
        !placedStones ||
        placedStones.length === 0 ||
        placedStones.indexOf(s.key) === -1
      );
    })
    .map((s) => {
      return {
        name: s.name,
        key: s.key,
        color: s.color,
        size: s.size,
        shape: s.shape,
        variant: s.variant,
        img: s.img,
      };
    });
  console.log("🚀 ~ remainingStones", remainingStones);
  console.log("🚀 ~ remainingStones winner?", game.winner);
  return remainingStones;
}

// function RemainingStonesControl(t, game: Game, remainingStones: { name: string | null | undefined; key: number | null | undefined; color: number | null | undefined; size: number | null | undefined; shape: number | null | undefined; variant: number | null | undefined; img: string | null | undefined; }[], theme) {
export function RemainingStonesControl(props: {
  game: Game;
  stones: StoneType[];
}) {
  const { t } = useTranslation();
  const { game, stones } = props;
  const remainingStones = getRemainingStones(game, stones);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "1px solid lightgray",
        // mx: 1,
        mt: 3,
        py: 2,
      }}
      display={"flex"}
      alignItems="center"
      flexDirection={"column"}
    >
      <Typography variant="overline">
        {t("gameControl:remainingStonesTitle", {
          count: game?.currentStone
            ? remainingStones.length - 1
            : remainingStones.length,
        })}
        :
      </Typography>
      <Box maxWidth={"100%"} width={"780px"}>
        <Box
          maxWidth={"600"}
          display={"flex"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          justifyContent={"center"}
        >
          {remainingStones.map((stone, index) => {
            if (game?.currentStone) {
              if (stone.key === game?.currentStone.key) {
                return <></>;
              }
            }

            return (
              <Avatar
                sx={{
                  margin: theme.spacing(1),
                  fontWeight: "inherit",
                  height: "75px",
                  width: "75px",
                  bgcolor: "white",
                  color: "white",
                }}
              >
                <StoneControl stone={stone} iconSet={game?.iconSet} />
              </Avatar>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
