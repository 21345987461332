import {
  Typography,
  Box,
  IconButton,
  FormControl,
  Button,
  keyframes,
  Tooltip,
  Link,
} from "@mui/material";
import { Game, GameSeries, StoneType } from "../../models";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { EditGameFormControl } from "./EditGameFormControl";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Psychology, School } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import LanguageSpeedDial from "../../controls/LanguageMenu";
import { t } from "i18next";

interface GameInfoHeaderProps {
  game: Game;
  gameStarted: boolean;
  myTurn: boolean;
  player?: number;
  series?: GameSeries;
  stone?: StoneType;
  updateGame: (data: {
    title?: string;
    description?: string;
    expertMode?: boolean;
  }) => void;
  editMode?: boolean;
}

export function GameInfoHeaderControl(props: GameInfoHeaderProps) {
  const { t } = useTranslation();
  const {
    series,
    game,
    gameStarted,
    myTurn,
    player,
    stone,
    updateGame,
    editMode = false,
  } = props;
  let [edit, setEdit] = React.useState<boolean>(false);
  let [showEditButton, setShowEditButton] = React.useState<boolean>(editMode);
  const hideEdit = () => {
    setEdit(false);
    setShowEditButton(false);
  };
  return (
    <>
      {/* <Box sx={{ position: "static", right: 20, mt: -2 }}> */}
      <Box
        sx={{
          float: "right",
          width: "100%",
          textAlign: "right",
          height: "10px",
          marginTop: "0px",
        }}
      >
        {edit && (
          <IconButton onClick={() => hideEdit()}>
            <CloseIcon />
          </IconButton>
        )}
        {!edit && (
          <IconButton onClick={() => setEdit(true)}>
            <SettingsIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      {edit && (
        <EditGameFormControl
          updateGame={updateGame}
          game={game}
          hideEdit={hideEdit}
        />
      )}

      <Box
        display="flex"
        flexDirection={"row"}
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        // onMouseEnter={() => setShowEditButton(true)}
        // onMouseLeave={() => setShowEditButton(false)}
        // sx={{ width: "100%", cursor: "pointer" }}
        width={"100%"}
      >
        {!edit && (
          <GameInfoHeaderTitleBox player={player} game={game} series={series} />
        )}
        {/* 
        {!gameStarted && !edit && (
          <Typography textAlign={"center"} variant="caption">
            {game.description}
          </Typography>
        )} */}
      </Box>
      {/* {gameStarted && !game.winner && (
        <GameStatusInfoControl
          myTurn={myTurn}
          stone={stone}
          player={player}
          game={game}
        />
      )} */}
    </>
  );
}

export function GameStatusInfoControl(props: {
  myTurn: boolean;
  stone: StoneType | undefined;
  player: number | undefined;
  game: Game;
}) {
  const { myTurn, stone, player, game } = props;
  const { t } = useTranslation();
  const blink = keyframes`
  from { opacity: 0.7; color:orange; }
  to { opacity: 1; }
`;
  return (
    <Box display="flex" flexDirection={"column"} alignItems="center">
      {/* <Typography variant="caption">
          Du bist {player === 1 ? game.player1 : game.player2}
        </Typography> */}
      {!myTurn && stone && (
        <Typography variant="body1">
          {t("gameControl:playerXTurn", {
            player:
              player === 2
                ? game.player1 || "Player 1"
                : game.player2 || "Player 2",
          })}
          {/* ist am Zug. */}
        </Typography>
      )}
      {myTurn && !stone && (
        <Typography>
          {t("gameControl:playerXChoosesNextStone", {
            player:
              player === 2
                ? game.player1 || "Player 1"
                : game.player2 || "Player 2",
          })}
        </Typography>
      )}

      {myTurn && stone && (
        <Typography sx={{ animation: `${blink} 1s linear infinite alternate` }}>
          {t("gameControl:itsYourTurnLabel")}
        </Typography>
      )}
      {!myTurn && !stone && (
        <Typography>{t("gameControl:chooseTheNextStone")}</Typography>
      )}
    </Box>
  );
}

function GameInfoHeaderTitleBox(props: {
  game: Game;
  series?: GameSeries;
  player?: number;
}) {
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = React.useState(false);

  const { game, series, player } = props;
  return (
    <Box display={"flex"} flexDirection="column">
      {series && (
        <Typography textAlign={"center"} color="gray" fontSize={"smaller"}>
          {t("gameControl:gameXOfSeries", { count: series?.gameIds?.length })}
        </Typography>
      )}
      <Box
        display={"flex"}
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography textAlign={"center"} variant="h6">
          {game.title}
        </Typography>
        {game.expertMode && <ExpertInfoIcon />}
        {/* <IconButton onClick={() => setShowDescription(!showDescription)}>
          {showDescription && <ExpandLessIcon fontSize="small" />}
          {!showDescription && <ExpandMoreIcon fontSize="small" />}
        </IconButton> */}
      </Box>
      <Typography textAlign={"center"} variant="caption">
        {player === 1 ? game.player1 : game.player2}
      </Typography>
      {showDescription && (
        <Box
          display={"flex"}
          flexDirection="column"
          px={2}
          pb={1}
          m={2}
          sx={{ backgroundColor: "white" }}
        >
          <Typography variant="overline">
            {t("GameEditForm.description_label")}
          </Typography>
          <Typography textAlign={"center"} variant="caption">
            {game.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
export function ExpertInfoIcon(props: { sx?: any }) {
  const { sx } = props;
  const { t } = useTranslation();
  return (
    <Link
      sx={{ textDecoration: "none", color: "black", ...sx }}
      href={"/info  "}
    >
      <Tooltip title={t("gameControl:expertModeTooltip")}>
        <School sx={{ ml: 1 }} />
      </Tooltip>
    </Link>
  );
}
