import { DataStore } from "@aws-amplify/datastore";
import { t } from "i18next";

import { useNavigate } from "react-router-dom";
import { servicesVersion } from "typescript";
import { Game, GameSeries } from "../models";
import { updateGameInDB } from "../views/GameControl";

interface SeriesType {
  id: string;
  games: Game[];
  gameIds: string[];
  player1: string;
  player2: string;
  player1Wins: number;
  player2Wins: number;
}

export function getMyGamesFromLocalStorage() {
  let returnArray: string[] = [];
  let foundSeries = [];
  let myGamesStr = localStorage.getItem("myGames");
  if (myGamesStr) {
    let myGames: string[] = JSON.parse(myGamesStr);
    if (myGames.length > 0) {
      returnArray = [...myGames];
    }
  }

  let mySeriesStr = localStorage.getItem("mySeries");
  if (mySeriesStr) {
    let mySeries: SeriesType[] = JSON.parse(mySeriesStr);
    if (mySeries.length > 0) {
      //   let mySeriesGameIds = mySeries.map((s) => s.games.map((g) => g.id));
      let mySeriesGameIds = mySeries.map((s) => s.gameIds).flat();
      returnArray = [...returnArray, ...mySeriesGameIds];
    }
  }
  return returnArray;
}

async function updateSeries(game: Game) {
  console.log("update Series: ", game);

  if (game.seriesId) {
    const original = await DataStore.query(GameSeries, game.seriesId!);

    let player1Wins = original?.player1Wins || 0;
    let player2Wins = original?.player2Wins || 0;
    if (game.winner === original?.player1) player1Wins++;
    if (game.winner === original?.player2) player2Wins++;
    //   const updateSeries: Partial<GameSeries> = {};
    console.log("🚀 ~ original series", original);
    const update = await DataStore.save(
      GameSeries.copyOf(original!, (updateSeries) => {
        updateSeries.gameIds = [...original?.gameIds!, game.id];
        updateSeries.player1Wins = player1Wins;
        updateSeries.player2Wins = player2Wins;
      })
    );
    console.log("🚀 ~ update series", update);
    return update;
  } else {
  }
}

async function createNewSeries(game: Game) {
  const series = {
    title: game.title,
    description: game.description,
    player1: game.player1,
    player2: game.player2,
    // games: [game],
    gameIds: [game.id],
  };
  const newSeries = await DataStore.save(new GameSeries(series));
  console.log("🚀 ~ newSeries", newSeries);
  return newSeries;
}

export async function addGameToLocalStorage(game?: Game) {
  if (!game) {
    return false;
  }
  console.log(`adding game ${game.id} to localStorage`);

  const myGamesStr = localStorage.getItem("myGames");
  if (myGamesStr) {
    let myGamesIds = JSON.parse(myGamesStr);
    if (myGamesIds.indexOf(game.id) === -1) {
      myGamesIds.push(game.id);
      localStorage.setItem("myGames", JSON.stringify(myGamesIds));
    }
  } else {
    localStorage.setItem("myGames", JSON.stringify([game.id]));
  }

  if (game.seriesId) {
    const mySeriesObj = await DataStore.query(GameSeries, game.seriesId);
    let mySeriesStr = localStorage.getItem("mySeries");
    if (mySeriesStr) {
      let mySeries: GameSeries[] = JSON.parse(mySeriesStr);
      if (mySeries.length > 0) {
        let mySeriesIds = mySeries.map((s) => s.id);
        if (mySeriesIds.indexOf(game.seriesId) === -1) {
          if (mySeriesObj) {
            mySeries.push(mySeriesObj);
            localStorage.setItem("mySeries", JSON.stringify(mySeries));
          }
        }
      }
    } else {
      localStorage.setItem("mySeries", JSON.stringify([mySeriesObj]));
    }
  }
}

// export function addGameToLocalStorage(gameId: string) {
//   console.log(`adding game ${gameId} to localStorage`);

//   const myGamesStr = localStorage.getItem("myGames");
//   if (myGamesStr) {
//     let myGames = JSON.parse(myGamesStr);
//     if (myGames.indexOf(gameId) === -1) {
//       myGames.push(gameId);
//       localStorage.setItem("myGames", JSON.stringify(myGames));
//     }
//   } else {
//     localStorage.setItem("myGames", JSON.stringify([gameId]));
//   }
// }

export function createNewBoard() {
  let board = Array();
  for (let i = 0; i < 16; i++) {
    let row = "A";
    let col = (i % 4) + 1;
    if (i >= 4) row = "B";
    if (i >= 8) row = "C";
    if (i >= 12) row = "D";
    const newField = { name: `${row}${col}`, key: i };
    board.push(newField);
  }
  return board;
}

export async function createRematch(
  game: Game,
  navigate?: ReturnType<typeof useNavigate>
) {
  console.log("🚀 ~ common: createRematch");
  let rematch: Game | undefined;

  const gameStorage = JSON.parse(localStorage.getItem(game.id) || "{}");
  const playerNoInRematch = Number(gameStorage.player) === 1 ? 2 : 1;

  if (game.seriesId) {
    console.log("add game to series", game.seriesId);
    // updateSeries(game);
    const newGame = {
      title: game?.title!,
      description: game?.description!,
      seriesId: game.seriesId,
      player1: game.player2!,
      player2: game.player1!,
      currentPlayer: 1,
      expertMode: game.expertMode || false,
      rejoinedPlayers: [playerNoInRematch],
    };

    rematch = await createGame(newGame);
    console.log("rematch is: ", rematch);
    await updateSeries(rematch!);
    await updateGameInDB(game!.id, {
      rematchId: rematch?.id,
    });
  } else {
    const series = await createNewSeries(game);
    console.log("🚀 ~ series is ", series);
    const newGame = {
      title: game?.title!,
      description: game?.description!,
      seriesId: series.id,
      player1: game.player2!,
      player2: game.player1!,
      expertMode: game.expertMode || false,
      currentPlayer: 1,
      rejoinedPlayers: [playerNoInRematch],
    };

    rematch = await createGame(newGame);
    console.log("rematch is: ", rematch);
    await updateSeries(rematch!);
    await updateGameInDB(game!.id, {
      seriesId: series.id,
      rematchId: rematch?.id,
    });
  }

  if (rematch) {
    localStorage.setItem(
      rematch.id,
      JSON.stringify({ player: playerNoInRematch })
    );
  }

  if (navigate && rematch) {
    navigate(`/game/${rematch.id}?rematch=true`);
    window.location.reload();
  }
}
export async function createGame(
  gameParams?: {
    count?: number;
    title?: string;
    description?: string;
    seriesId?: string;
    currentPlayer?: number;
    player1?: string;
    player2?: string;
    rejoinedPlayers?: number[];
    expertMode?: boolean;
  },
  navigate?: ReturnType<typeof useNavigate>
) {
  //   return async () => {
  const count = gameParams?.count || 0;
  const game = {
    title:
      gameParams?.title ||
      t("CreateGameDefaults.title", { count: (count || 0) + 1 }),
    description:
      gameParams?.description ||
      t("CreateGameDefaults.description", {
        count: (count || 0) + 1,
      }),
    seriesId: gameParams?.seriesId || null,
    player1: gameParams?.player1 || null,
    player2: gameParams?.player2 || null,
    currentPlayer: gameParams?.currentPlayer || null,
    rejoinedPlayers: gameParams?.rejoinedPlayers || null,
    expertMode: gameParams?.expertMode || false,
    board: createNewBoard(),
  };

  const newGame = await DataStore.save(new Game(game));
  console.log("🚀 ~ newGame", newGame);
  // addGameToLocalStorage(newGame.id!);
  addGameToLocalStorage(newGame);
  if (navigate) {
    navigate(`/game/${newGame.id}`, { replace: true });
  } else {
    return newGame;
  }
  //   };
}

export function dateSorter<T extends object>(
  property: keyof T,
  direction: "ASC" | "DESC"
): (a: T, b: T) => number {
  return (a: T, b: T) => {
    const valA = a[property];
    const valB = b[property];
    if (valA && valB) {
      if (direction === "DESC") {
        return valA > valB ? -1 : valA < valB ? 1 : 0;
      } else {
        return valA < valB ? -1 : valA > valB ? 1 : 0;
      }
    } else {
      return 0;
    }
  };
}

export const isMyTurn = (game: Game) => {
  if (game.winRow || game.winner) {
    return false;
  }
  const item = localStorage.getItem(game.id);
  if (item) {
    // console.log("isMyTurn?");
    const gameInfo = JSON.parse(item);
    if (gameInfo) {
      const returnVal = Number(gameInfo.player) === game.currentPlayer;
      //   console.log("🚀 ~ gameInfo", gameInfo);
      //   console.log("🚀 ~ returnVal", returnVal);
      return returnVal;
    }
  }

  return false;
};

export const whoAmI = (game: Game) => {
  const item = localStorage.getItem(game.id);
  if (item) {
    const gameInfo = JSON.parse(item);
    if (gameInfo) {
      if (Number(gameInfo.player) === 1) return game.player1;
      if (Number(gameInfo.player) === 2) return game.player2;
    }
  }
  return "";
};

export const myOpponent = (game: Game) => {
  const item = localStorage.getItem(game.id);
  if (item) {
    const gameInfo = JSON.parse(item);
    if (gameInfo) {
      if (Number(gameInfo.player) === 1) return game.player2;
      if (Number(gameInfo.player) === 2) return game.player1;
    }
  }
  return "";
};
