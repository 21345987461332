import React from "react";

import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Game } from "../models";
import { Box, Typography, IconButton, Link } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export function ShareGameLinkControl(props: { game: Game }) {
  const { game } = props;
  const { t } = useTranslation();
  i18next.loadNamespaces("shareGame", (err, t) => {
    /* ... */
  });
  let [copied, setCopied] = React.useState<boolean>(false);
  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
  };

  return (
    <Box m={2} display="flex" flexDirection={"column"} alignItems="center">
      <Typography variant="h6" m={2} textAlign={"center"} gutterBottom>
        {t("shareGame:gameNotStarted")}
      </Typography>
      <Typography variant="caption" display={"block"}>
        {t("shareGame:shareLinkLabel")}
      </Typography>
      <Box
        typography={"caption"}
        display="flex"
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Link
          mr={1}
          fontSize={"smaller"}
          typography={"caption"}
          sx={{ cursor: "pointer" }}
          onClick={copyLink}
        >
          {window.location.href}
          {copied && <CheckIcon sx={{ ml: 2 }} fontSize="inherit" />}
        </Link>
      </Box>
      <Box
        mt={2}
        display="flex"
        alignItems={"flex-start"}
        justifyContent="center"
        width={"100%"}
      >
        <Box
          sx={{
            width: "30%",
            maxWidth: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          flexDirection={"column"}
        >
          <Typography variant="overline">{t("shareGame:copyLabel")}</Typography>
          <IconButton size="small" onClick={copyLink}>
            {!copied && <ContentCopyIcon fontSize="inherit" />}
            {copied && <CheckIcon sx={{ ml: 1 }} fontSize="inherit" />}
          </IconButton>
          {/* {copied && (
          <Typography mr={1} textAlign="center" variant="caption">
            Link kopiert
            <CheckIcon sx={{ ml: 1 }} fontSize="inherit" />
          </Typography>
        )} */}
        </Box>

        {/* {copied && (
          <>
            <CheckIcon fontSize="inherit" />
            <Typography variant="caption">kopiert</Typography>
          </>
        )} */}
        <Box
          sx={{
            width: "30%",
            maxWidth: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="overline">
            {t("shareGame:whatsAppLabel")}
          </Typography>
          <Link
            target="_blank"
            href={`https://api.whatsapp.com/send?text=Du wurdest zu dem Spiel "${game.title}" eingeladen.\n Klicke auf den folgenden Link um an dem Spiel teilzunehmen:  ${window.location.href}`}
          >
            <WhatsAppIcon />
          </Link>
        </Box>
        <Box
          sx={{
            width: "30%",
            maxWidth: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="overline">
            {t("shareGame:eMailAppLabel")}
          </Typography>
          <Link
            target="_blank"
            href={`mailto:?&subject=Neues Spiel&body=Du wurdest zu dem Spiel "${game.title}" eingeladen.\nKlicke auf den folgenden Link um an dem Spiel teilzunehmen:  ${window.location.href}`}
          >
            <EmailIcon />
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
