import {
  Box,
  FormControl,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  IconButton,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { ReactElement } from "react";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { GameIcon, getAllSets } from "../../controls/IconSet";
import CloseIcon from "@mui/icons-material/Close";
import { updateGame } from "../../graphql/mutations";
import { Game } from "../../models";
import { useTranslation } from "react-i18next";
import { getGameConfig } from "../../utils/gameConfig";

interface EditGameFormProps {
  game: Game;

  updateGame: (data: {
    title?: string;
    description?: string;
    iconSet?: number;
    expertMode?: boolean;
  }) => void;
  hideEdit: () => void;
}

interface FormValues {
  title?: string;
  description?: string;
  iconSet?: number;
  expertMode?: boolean;
}
export function EditGameFormControl(props: EditGameFormProps) {
  const { game, updateGame, hideEdit } = props;
  const { t } = useTranslation();
  const updateGameName = (data: FormValues) => {
    console.log("🚀 ~ data", data);
    console.log("🚀 ~ data", { ...data, iconSet: iconSet });
    updateGame({ ...data, iconSet: iconSet, expertMode: expertMode });
    hideEdit();
  };

  const initSet = game.iconSet && game.iconSet > 0 ? game.iconSet : 1;
  const [iconSet, setIconSet] = React.useState(initSet);
  const [expertMode, setExpertMode] = React.useState(game.expertMode || false);

  const [menuItems, setMenuItems] = React.useState<ReactElement[]>(() => {
    let menuItems = [];
    for (let i = 1; i <= getAllSets().length; i++) {
      const stoneSet = i;
      menuItems.push(
        <MenuItem key={`menuItem-${i}`} value={stoneSet}>
          <GameIcon stoneSet={stoneSet} color={1} sx={{ mr: 2 }} />
          <GameIcon stoneSet={stoneSet} variant={1} sx={{ mx: 2 }} />
        </MenuItem>
      );
    }
    return menuItems;
  });

  const handleChange = (event: SelectChangeEvent) => {
    console.log("iconSet is: ", event.target.value);
    setIconSet(Number(event.target.value));
  };

  const toggleExpertMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("expert mode is: ", event.target.checked);
    setExpertMode(event.target.checked);
  };

  return (
    <>
      {
        <Box m={2} mb={3}>
          <FormContainer onSuccess={updateGameName}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <FormControl sx={{ width: "30ch" }}>
                <TextFieldElement
                  label={t("GameEditForm.title_label")}
                  autoFocus={true}
                  id="title"
                  defaultValue={game.title}
                  name="title"
                  placeholder={game.title || ""}
                  size="small"
                  sx={{ mb: 1 }}
                  aria-describedby="place-helper-text"
                />
              </FormControl>
              <FormControl sx={{ width: "30ch" }}>
                <TextFieldElement
                  label={t("GameEditForm.description_label")}
                  id="description"
                  name="description"
                  placeholder={game.description || ""}
                  size="small"
                  sx={{ mb: 1 }}
                  aria-describedby="place-helper-text"
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "30ch" }}>
                <InputLabel id="demo-simple-select-helper-label">
                  {t("GameEditForm.choose_stoneset_label")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={String(iconSet)}
                  label={t("GameEditForm.stones_label")}
                  onChange={handleChange}
                >
                  {menuItems.map((m) => m)}
                </Select>
                <FormHelperText sx={{ mx: 0 }}>
                  {t("GameEditForm.choose_stoneset_label")}
                </FormHelperText>
              </FormControl>
              {/* {!game.gameHasStarted && ( */}
              <FormControl sx={{ mb: 1, width: "30ch" }}>
                <FormControlLabel
                  disabled={game.gameHasStarted || false}
                  control={
                    <Switch checked={expertMode} onChange={toggleExpertMode} />
                  }
                  label={t("GameEditForm.expert_mode_label")}
                ></FormControlLabel>
                {game.gameHasStarted && (
                  <FormHelperText sx={{ mx: 0 }}>
                    <Typography fontSize={"70%"}>
                      {t("GameEditForm.expert_mode_info")}
                    </Typography>
                  </FormHelperText>
                )}
              </FormControl>
              {/* )} */}

              <Button type={"submit"} variant="outlined" color="warning">
                {t("Buttons.save")}
              </Button>
            </Box>
          </FormContainer>
        </Box>
      }
    </>
  );
}
