import { createTheme, ThemeProvider } from "@mui/material/styles";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";
import { GameView } from "./views/GameControl";
import { GameInfoView } from "./views/GameInfoView";
import { ViewWrapper } from "./views/ViewWrapper";
import "./i18n";
import i18next from "i18next";
import i18n from "./i18n";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});
// i18next.init(
//   {
//     // lng: "de",
//     // ns: ["translation", "gameInfo", "shareGame"],
//     ns: ["translation"],
//     defaultNS: "translation",
//   },
//   (err, t) => {
//     // i18next.t("myKey"); // key in moduleA namespace (defined default)
//     // i18next.t("common:myKey"); // key in common namespace (not recommended with ns prefix when used in combination with natural language keys)
//     // // better use the ns option:
//     // i18next.t("myKey", { ns: "common" });
//     // i18next.changeLanguage("de");
//   }
// );
// .then(() => {
//   const root = ReactDOM.createRoot(
//     document.getElementById("root") as HTMLElement
//   );
//   root.render(
//     <ThemeProvider theme={theme}>
//       <BrowserRouter>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               <ViewWrapper>
//                 <App />
//               </ViewWrapper>
//             }
//           />
//           <Route
//             path="/info"
//             element={
//               <ViewWrapper>
//                 <GameInfoView />
//               </ViewWrapper>
//             }
//           />
//           <Route path="game">
//             <Route
//               path=":gameId"
//               element={
//                 <ViewWrapper>
//                   <GameView />
//                 </ViewWrapper>
//               }
//             />
//           </Route>
//         </Routes>
//       </BrowserRouter>
//     </ThemeProvider>
//   );
// });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AppContainer = (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ViewWrapper>
              <App />
            </ViewWrapper>
          }
        />
        <Route
          path="/info"
          element={
            <ViewWrapper>
              <GameInfoView />
            </ViewWrapper>
          }
        />
        <Route path="game">
          <Route
            path=":gameId"
            element={
              <ViewWrapper>
                <GameView />
              </ViewWrapper>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);
root.render(AppContainer);
// i18n.on("loaded", () => {
//   console.log("all loaded!!!");
//   root.render(AppContainer);
// });

// i18n.on("initialized", () => {
//   console.log("lang initialized...");
//   root.render(AppContainer);
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
