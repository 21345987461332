import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  Typography,
  Button,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { StoneType } from "../API";
import { getGameConfig } from "../utils/gameConfig";
import { GameInfoView } from "./GameInfoView";
import { PaperComponent } from "./SelectStoneDialog";
import LanguageMenu from "../controls/LanguageMenu";

export interface InfoDialogProps {
  onClose: (b: boolean) => void;
}
export function GameInfoDialog(props: InfoDialogProps) {
  console.log("GameInfoDialog Popup...");

  const { onClose } = props;
  const [open, setOpen] = React.useState(true);

  const { t } = useTranslation();

  //   const theme = useTheme();
  //   const xs = useMediaQuery(theme.breakpoints.down("md"));
  const handleClose = () => {
    localStorage.setItem("hideInfo", "true");

    setOpen(false);
    onClose(true);
    // if (selectedValue) onClose(selectedValue);
  };

  //   const confirmStoneSelection = (stone: StoneType) => {
  //     onClose(stone);
  //   };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          // disableTypography
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton>
            <LanguageMenu />
          </IconButton>
          <Typography flexGrow={1} variant="h5" textAlign={"center"}>
            {t("gameInfoDialogTitle")}
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
              handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <DialogTitle style={{ cursor: "move" }}>
          <Typography variant="body1" textAlign={"center"}>
            {t("gameInfoDialogTitle")}
          </Typography>
          <Typography variant="caption"></Typography>
        </DialogTitle> */}
        {/* <Box p={2} mt={0} mb={2} textAlign="center"> */}
        <Box p={0} mt={0} mb={2} textAlign="center">
          <GameInfoView title="" />
          <Button variant="contained" color="success" onClick={handleClose}>
            {t("dontShowAgainButton")}
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
