import { School } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

export function GameInfoView(props: { title?: string }) {
  // React.useEffect(() => {
  const [nsLoaded, setNsLoaded] = React.useState(false);

  React.useEffect(() => {
    i18next.loadNamespaces("gameInfo", (err, t) => {
      /* ... */
      if (!err) {
        setNsLoaded(true);
      } else {
        console.log("ERROR LOADING NS", err);
      }
    });
  }, []);

  const { t } = useTranslation();
  if (!nsLoaded) return <></>;

  const { title = t("gameInfo:title") } = props;

  return (
    <Box
      marginX={"auto"}
      maxWidth={"600px"}
      p={2}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      {title !== "" && (
        <Typography textAlign={"center"} variant="h5" gutterBottom>
          {title}
        </Typography>
      )}

      <Typography textAlign={"center"} variant="overline" fontSize={"medium"}>
        {t("gameInfo:p1.title")}:
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("gameInfo:p1.text1")}
      </Typography>

      <Typography variant="body1" gutterBottom>
        {t("gameInfo:p1.text2")}
        <br />
        {t("gameInfo:p1.text3")}
      </Typography>

      <Typography textAlign={"center"} variant="overline" fontSize={"medium"}>
        {t("gameInfo:p2.title")}:
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("gameInfo:p2.text1")}
      </Typography>
      <Typography textAlign={"center"} variant="overline" fontSize={"medium"}>
        {t("gameInfo:p3.title")}:
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("gameInfo:p3.text1")}
        {/* (siehe Beispiele). */}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("gameInfo:p3.text2")}
      </Typography>
      <Box mt={2} display={"flex"} alignItems="center">
        <Typography
          textAlign={"center"}
          variant="overline"
          fontSize={"medium"}
          fontWeight={"bold"}
        >
          {t("gameInfo:p4.title")}:
        </Typography>
        <School sx={{ ml: 2 }} />
      </Box>

      <Typography variant="body1" gutterBottom>
        {t("gameInfo:p4.text1")}
        <br />
        {t("gameInfo:p4.text2")}
        <br />
        <Typography component={"strong"} fontWeight="bold">
          {t("gameInfo:p4.text3")}
        </Typography>
      </Typography>
    </Box>
  );
}
