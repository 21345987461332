import {
  Box,
  Button,
  FormControl,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
// import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useTranslation } from "react-i18next";
import { Game } from "../models";

interface FormValues {
  player1?: string;
  player2?: string;
  name?: string;
}
export function ChoosePlayerControl(props: {
  selectPlayer: (x: string, game: Game, name?: string) => void;
  game: Game;
}) {
  let [player, setPlayer] = React.useState<number>();
  const { t } = useTranslation();
  const { game, selectPlayer } = props;
  const checkName = (data: FormValues) => {
    console.log("🚀 ~ data", data);
    console.log("🚀 ~ player", player);
    if (player === 1) {
      selectPlayer("1", game, data.name);
    } else if (player === 2) {
      selectPlayer("2", game, data.name);
    }
  };

  console.log("CHOOSE PLAYER", game);

  const playAsPlayer = (x: number) => {
    localStorage.setItem(game.id, JSON.stringify({ player: x }));
    window.location.reload();
  };

  let playerCount = 0;
  if (game.player1) {
    playerCount++;
  }
  if (game.player2) {
    playerCount++;
  }

  // test
  return (
    <Box
      display="flex"
      justifyContent={"flex-start"}
      flexDirection="column"
      alignItems={"center"}
    >
      {playerCount == 2 && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100vh",
            width: "100vw",
            pt: 4,
            backgroundColor: "whitesmoke",
          }}
          display="flex"
          justifyContent={"flex-start"}
          flexDirection="column"
          alignItems={"center"}
        >
          <Paper elevation={4} sx={{ p: 4 }}>
            <Box display="flex" flexDirection={"column"} alignItems="center">
              <Typography variant="h6">
                {t("ChoosePlayerControl.gameAlreadyInProgress")}
              </Typography>
              <Typography variant="body1">
                {t("ChoosePlayerControl.whoDoYouWantToPlayAs")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                  // flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => playAsPlayer(1)}
                  sx={{ m: 1 }}
                  fullWidth
                  variant="outlined"
                >
                  {game.player1}
                </Button>
                <Button
                  onClick={() => playAsPlayer(2)}
                  sx={{ m: 1 }}
                  fullWidth
                  variant="outlined"
                  color="warning"
                >
                  {game.player2}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
      {playerCount < 2 && (
        <>
          <Typography variant="h6">
            {t("ChoosePlayerControl.chooseAPlayer")}:
          </Typography>
          <Box display="flex" justifyContent={"center"} minWidth="400px" m={1}>
            <FormContainer onSuccess={checkName}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <FormControl sx={{ m: 1, width: "30ch" }}>
                  {/* <TextFieldElement
            label="Dein Name (optional)"
            name="player1"
            id="player1"
            aria-describedby="phone-helper-text"
          /> */}

                  {/* <TextFieldElement
                  disabled={game.player1 !== null}
                  label="Player 1"
                  id="player1"
                  name="player1"
                  size="small"
                  sx={{ mb: 2 }}
                  aria-describedby="place-helper-text"
                /> */}

                  <TextFieldElement
                    label={t("ChoosePlayerControl.nameLabel")}
                    id="name"
                    name="name"
                    size="small"
                    // sx={{ mb: 2 }}
                    aria-describedby="place-helper-text"
                  />
                </FormControl>

                <FormControl
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    flexDirection: "row",
                    // flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {/* <TextFieldElement
                  disabled={game.player2 !== null}
                  label="Player 2"
                  id="player2"
                  name="player2"
                  size="small"
                  sx={{ mb: 2 }}
                  aria-describedby="place-helper-text"
                /> */}
                  {/* {game.player1 === null && ( */}
                  <Button
                    disabled={game.player1 !== null}
                    sx={{ mx: 1 }}
                    fullWidth
                    type="submit"
                    variant="outlined"
                    onClick={() => {
                      setPlayer(1);
                    }}
                  >
                    {t("CreateGameDefaults.player1")}
                  </Button>
                  {/* )}
              {game.player2 === null && ( */}
                  <Button
                    disabled={game.player2 !== null}
                    sx={{ mx: 1 }}
                    fullWidth
                    type={"submit"}
                    variant="outlined"
                    color="warning"
                    onClick={() => {
                      setPlayer(2);
                    }}
                  >
                    {t("CreateGameDefaults.player2")}
                    {/* {game.player2 || "Spieler 2"} */}
                  </Button>
                  {/* )} */}
                </FormControl>
              </Box>
            </FormContainer>
          </Box>
        </>
      )}
    </Box>
  );
}
