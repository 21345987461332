import { Box, Button, Typography } from "@mui/material";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createGame } from "../utils/common";

export function WelcomeView() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const createNewGame = () => createGame({ count: 1 }, navigate);
  return (
    <Box
      marginX={"auto"}
      maxWidth={"600px"}
      p={2}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
    >
      <Typography textAlign={"center"} variant="h6" mb={3}>
        AlleViere.de
      </Typography>

      <Typography textAlign={"center"} variant="overline">
        {t("welcomeTitle")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("welcomeText")}
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => createNewGame()}
      >
        {t("createYourFirstGame")}
      </Button>
    </Box>
  );
}
