import Brightness2Icon from "@mui/icons-material/Brightness2";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import FemaleIcon from "@mui/icons-material/Female";
import LocalAirportIcon from "@mui/icons-material/LocalAirport";
import LocalPizzaIcon from "@mui/icons-material/LocalPizza";
import LunchDiningIcon from "@mui/icons-material/LunchDining";
import MaleIcon from "@mui/icons-material/Male";
import SailingIcon from "@mui/icons-material/Sailing";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import SportsGolfIcon from "@mui/icons-material/SportsGolf";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsIcon from "@mui/icons-material/Sports";
import WineBarIcon from "@mui/icons-material/WineBar";
import SportsBarIcon from "@mui/icons-material/SportsBar";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import SquareIcon from "@mui/icons-material/Square";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import WeekendIcon from "@mui/icons-material/Weekend";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";

import GavelIcon from "@mui/icons-material/Gavel";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";

import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import CoffeeIcon from "@mui/icons-material/Coffee";

import KeyboardIcon from "@mui/icons-material/Keyboard";
import MouseIcon from "@mui/icons-material/Mouse";

import LockIcon from "@mui/icons-material/Lock";
import KeyIcon from "@mui/icons-material/Key";

import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import ShowChartIcon from "@mui/icons-material/ShowChart";

import SportsScoreIcon from "@mui/icons-material/SportsScore";
import SportsMotorsportsIcon from "@mui/icons-material/SportsMotorsports";

import SportsFootballIcon from "@mui/icons-material/SportsFootball";

import { StoneType } from "../models";

interface IconSetProps extends StoneType {
  stoneSet: number;
  sx?: any;
}

// export const GAME_ICON_SETS_COUNT = 10;

export function GameIcon(props: IconSetProps) {
  const { ...stone } = props;

  // build style
  const allSets = getAllSets(props, stone);

  let stoneSet =
    props.stoneSet > allSets.length
      ? 0
      : props.stoneSet - 1 >= 0
      ? props.stoneSet - 1
      : 0;
  // console.log("GET SET: ", stoneSet);

  return allSets[stoneSet]()[stone.variant || 0];
}

export function getAllSets(
  props?: IconSetProps,
  stone?: {
    stoneSet: number;
    sx?: any;
    name?: string | null | undefined;
    key?: number | null | undefined;
    color?: number | null | undefined;
    size?: number | null | undefined;
    shape?: number | null | undefined;
    variant?: number | null | undefined;
    img?: string | null | undefined;
  }
) {
  const bs = (overrides?: {
    colors?: [string, string];
    size?: [string, string];
  }) => {
    const defaultColors = overrides?.colors || ["blue", "red"];
    const defaultSize = overrides?.size || ["h3", "h6"];
    if (props) {
      return {
        ...props.sx,
        typography: stone!.size === 1 ? defaultSize[0] : defaultSize[1],
        color: stone!.color === 1 ? defaultColors[0] : defaultColors[1],
      };
    }
  };
  let sx = { sx: bs() };

  const allSets = [
    // 1
    () => {
      sx = { sx: bs({ colors: ["red", "black"] }) };
      return [<CoronavirusIcon {...sx} />, <VaccinesIcon {...sx} />];
    },

    // 2
    () => {
      sx = { sx: bs({ colors: ["gold", "black"] }) };
      return [<WbSunnyIcon {...sx} />, <Brightness2Icon {...sx} />];
    },
    // 3
    () => {
      sx = { sx: bs({ colors: ["red", "blue"] }) };
      return [<FemaleIcon {...sx} />, <MaleIcon {...sx} />];
    },
    // 4
    () => {
      sx = { sx: bs({ colors: ["blue", "black"] }) };
      return [<SailingIcon {...sx} />, <LocalAirportIcon {...sx} />];
    },

    // 5
    () => [
      <SentimentVeryDissatisfiedIcon {...sx} />,
      <SentimentSatisfiedAltIcon {...sx} />,
    ],
    // 6
    () => {
      sx = { sx: bs({ colors: ["red", "gold"] }) };
      return [<LocalPizzaIcon {...sx} />, <LunchDiningIcon {...sx} />];
    },

    // 7
    () => {
      sx = { sx: bs({ colors: ["lightgreen", "black"] }) };
      return [<SportsGolfIcon {...sx} />, <GolfCourseIcon {...sx} />];
    },
    // 8
    () => {
      sx = { sx: bs({ colors: ["red", "black"] }) };
      return [<SportsIcon {...sx} />, <SportsSoccerIcon {...sx} />];
    },
    // 9
    () => {
      sx = { sx: bs({ colors: ["gold", "darkred"] }) };
      return [<SportsBarIcon {...sx} />, <WineBarIcon {...sx} />];
    },
    // 10
    () => {
      sx = { sx: bs({ colors: ["lightgray", "black"] }) };
      return [<Brightness1Icon {...sx} />, <SquareIcon {...sx} />];
    },
    // 11
    () => {
      sx = { sx: bs({ colors: ["green", "red"] }) };
      return [<ThumbUpOffAltIcon {...sx} />, <ThumbDownOffAltIcon {...sx} />];
    },
    // 12
    () => {
      sx = { sx: bs({ colors: ["silver", "brown"] }) };
      return [<FitnessCenterIcon {...sx} />, <WeekendIcon {...sx} />];
    },

    // 13
    () => {
      sx = { sx: bs({ colors: ["black", "lime"] }) };
      return [<AppleIcon {...sx} />, <AndroidIcon {...sx} />];
    },
    // 14
    () => {
      sx = { sx: bs({ colors: ["gold", "silver"] }) };
      return [<RocketLaunchIcon {...sx} />, <SatelliteAltIcon {...sx} />];
    },
    // 15
    () => [<CoffeeIcon {...sx} />, <EmojiFoodBeverageIcon {...sx} />],
    // 16
    () => {
      sx = { sx: bs({ colors: ["lightgray", "black"] }) };
      return [<MouseIcon {...sx} />, <KeyboardIcon {...sx} />];
    },
    // 17
    () => {
      sx = { sx: bs({ colors: ["silver", "gold"] }) };
      return [<KeyIcon {...sx} />, <LockIcon {...sx} />];
    },
    // 18
    () => {
      sx = { sx: bs({ colors: ["black", "red"] }) };
      return [<CandlestickChartIcon {...sx} />, <ShowChartIcon {...sx} />];
    },
    // 19
    () => {
      sx = { sx: bs({ colors: ["black", "lightgrey"] }) };
      return [<SportsFootballIcon {...sx} />, <SportsSoccerIcon {...sx} />];
    },
    // 20
    () => {
      sx = { sx: bs({ colors: ["gold", "black"] }) };
      return [<SportsMotorsportsIcon {...sx} />, <SportsScoreIcon {...sx} />];
    },
    // 21
    () => {
      sx = { sx: bs({ colors: ["darkorange", "black"] }) };
      return [<GavelIcon {...sx} />, <DirectionsCarIcon {...sx} />];
    },
    // 22
    () => {
      return [
        <ChildFriendlyIcon {...sx} />,
        <BabyChangingStationIcon {...sx} />,
      ];
    },
  ];
  return allSets;
}

export function IconSetOld(props: IconSetProps) {
  const { stoneSet, ...stone } = props;
  // return <GameIcon stoneSet={stoneSet} {...stone} />;

  // switch (stoneSet) {
  //   case 1:
  //     colors = ["red", "blue"];
  //     if (stone.variant === 1)
  //       return (
  //         <>
  //           <CoronavirusIcon
  //             sx={{
  //               color: stone!.color === 1 ? colors[0] : colors[1],
  //               typography: stone!.size === 1 ? "h3" : "h6",
  //               ...sx,
  //             }}
  //           />
  //         </>
  //       );
  //     else
  //       return (
  //         <VaccinesIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     break;
  //   case 2:
  //     colors = ["gold", "black"];
  //     if (stone.variant === 1)
  //       return (
  //         <Brightness2Icon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     else
  //       return (
  //         <WbSunnyIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     break;
  //   case 3:
  //     if (stone.variant === 1)
  //       return (
  //         <FemaleIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     else
  //       return (
  //         <MaleIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     break;
  //   case 4:
  //     if (stone.variant === 1)
  //       return (
  //         <LocalAirportIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     else
  //       return (
  //         <SailingIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     break;
  //   case 5:
  //     if (stone.variant === 1)
  //       return (
  //         <SentimentSatisfiedAltIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     else
  //       return (
  //         <SentimentVeryDissatisfiedIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     break;
  //   case 6:
  //     if (stone.variant === 1)
  //       return (
  //         <LunchDiningIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     else
  //       return (
  //         <LocalPizzaIcon
  //           sx={{
  //             color: stone!.color === 1 ? colors[0] : colors[1],
  //             typography: stone!.size === 1 ? "h3" : "h6",
  //             ...sx,
  //           }}
  //         />
  //       );
  //     break;
  //   default:
  //     return <MoreHorizIcon />;
  //     break;
  // }
}
