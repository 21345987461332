import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { StoneControl } from "../../controls/StoneControl";
import { Game, StoneType } from "../../models";
import { Tile } from "./GameBoardControl";

export function CurrentStoneInfoControl(props: {
  stone: StoneType | undefined;
  game: Game;
}) {
  const { t } = useTranslation();
  const { stone, game } = props;
  return (
    <>
      <Typography variant="overline">{t("Game.currentStone")}:</Typography>

      <Box mb={1} width="75px" height="75px">
        <Tile
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <StoneControl stone={stone} iconSet={game.iconSet} />
        </Tile>
        {/* <StoneControl stone={stone} iconSet={game.iconSet} /> */}
      </Box>
    </>
  );
}
