import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";
import { Hub } from "aws-amplify";
import { DataStore } from "@aws-amplify/datastore";
import React from "react";
import { useLocation } from "react-router";
import { off } from "process";
import LanguageMenu from "../controls/LanguageMenu";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { GameInfoDialog } from "./GameInfoDialog";
// import i18next from "i18next";
// import i18n from "../i18n";

export function ViewWrapper(props: { children?: React.ReactNode }) {
  const location = useLocation();
  const [hideInfo, setHideInfo] = React.useState<boolean>(
    Boolean(localStorage.getItem("hideInfo")) || false
  );
  const [tabHasFocus, setTabHasFocus] = React.useState(true);
  const [offline, setOffline] = React.useState(false);
  const { t } = useTranslation();

  console.log("location is: ", location);
  const [lngs, setLngs] = React.useState({ en: { nativeName: "English" } });

  React.useEffect(() => {
    // Create listener
    // i18n.changeLanguage("de");
    const listener = Hub.listen("datastore", async (hubData) => {
      // console.log("hubstatus: listen");

      const { event, data } = hubData.payload;
      if (event === "networkStatus") {
        console.log(`hubstatus: User has a network connection: ${data.active}`);

        if (data.active) {
          console.log("sync datastore...");
          // DataStore.clear();
          DataStore.start();
        }

        setOffline(!data.active);
      }
    });

    // Remove listener

    return () => {
      listener();
    };
  }, []);

  return (
    // <Box sx={{ backgroundColor: "yellow", height: "100%" }}>
    // <Box sx={{ height: "100%" }}>
    <Box>
      {!hideInfo && <GameInfoDialog onClose={setHideInfo} />}
      {offline && (
        <Box
          sx={{
            backgroundColor: "red",
            width: "100%",
            textAlign: "center",

            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="overline" fontWeight={"bold"}>
            {t("Toolbar.noServerConnection")}
          </Typography>
          <Typography variant="caption" gutterBottom>
            {t("Toolbar.pleaseCheckYourConnection")}
          </Typography>
        </Box>
      )}
      <AppBar
        position="static"
        sx={{ backgroundColor: "whitesmoke", color: "black" }}
      >
        <Toolbar>
          {/* <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton> */}
          {location.pathname !== "/" && (
            <Button color="inherit" href="/" sx={{ float: "left", margin: 1 }}>
              {t("MenuItems.myGames")}
            </Button>
          )}
          <Button
            color="inherit"
            href="/info"
            sx={{ float: "left", margin: 1 }}
          >
            {t("MenuItems.gameRules")}
          </Button>
          {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              News
            </Typography> */}
          {/* <Button color="inherit">Login</Button> */}
          <LanguageMenu />
        </Toolbar>
      </AppBar>
      {/* <Box sx={{ backgroundColor: "green" }}>{props.children}</Box> */}
      <Box>{props.children}</Box>
    </Box>
  );
}
