// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { StoneSet, Stone, Game, GameSeries, StoneType, Field } = initSchema(schema);

export {
  StoneSet,
  Stone,
  Game,
  GameSeries,
  StoneType,
  Field
};