import { Avatar, Box, keyframes, styled } from "@mui/material";
import { Stone, StoneType } from "../models";
import CoronavirusOutlinedIcon from "@mui/icons-material/CoronavirusOutlined";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";
import PendingIcon from "@mui/icons-material/Pending";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { GameIcon } from "./IconSet";

const BorderedStone = styled("div")(({ theme }) => ({
  width: "90%",
  height: "90%",
  backgroundColor: "whitesmoke",
  border: "1px dashed grey",
  borderRadius: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StoneComponent = styled("div")(({ theme }) => ({
  width: "90%",
  height: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "white",
  fontSize: "large",
}));

export function StoneControl(props: {
  stone?: StoneType;
  preview?: boolean;
  iconSet?: number | null;
}) {
  // console.log("🚀 ~ SC props", props);
  const { stone, iconSet: stoneSet = 1 } = props;

  const blink = keyframes`
  from { opacity: 0.1; }
  to { opacity: 1; }
`;
  const getStone = (stone?: StoneType) => {
    let icon;
    if (!stone) {
      return (
        <StoneComponent>
          <MoreHorizIcon
            sx={{ animation: `${blink} 1s linear infinite alternate` }}
          />
        </StoneComponent>
      );
    }
    if (stone.shape === 1) {
      icon = (
        <StoneComponent>
          <GameIcon stoneSet={stoneSet || 1} {...stone} />
        </StoneComponent>
      );
    } else {
      icon = (
        <BorderedStone>
          <GameIcon stoneSet={stoneSet || 1} {...stone} />
        </BorderedStone>
      );
    }
    return icon;
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          color: props.preview ? "red" : "inherit",
        }}
      >
        {getStone(stone)}
      </Box>
    </>
  );
}
